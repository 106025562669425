import { isEmpty } from 'lodash';
import React, { PropsWithChildren, useCallback, useMemo, useReducer } from 'react';
import { Link } from 'react-router-dom';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, ListItemButton, ListItemText } from '@mui/material';

import useStyles from './styles';

interface Props {
    title: string;
    link?: string;
    accent?: boolean;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    className?: string;
}

const MenuItem: React.FC<PropsWithChildren<Props>> = ({ title, link, accent, children, onClick, className }) => {
    const { classes, cx } = useStyles();
    const [isOpen, toggleIsOpen] = useReducer((state) => !state, false);
    const hasSubMenu = !!children && !isEmpty(children);

    const handleClick = useCallback(
        (event) => {
            if (hasSubMenu) {
                toggleIsOpen();
            }
            onClick?.(event);
        },
        [hasSubMenu, onClick]
    );

    const chevron = useMemo(() => {
        switch (true) {
            case isEmpty(children):
                return null;
            case isOpen == true:
                return <ExpandLess />;
            case isOpen === false:
                return <ExpandMore />;
            default:
                return null;
        }
    }, [children, isOpen]);

    return (
        <>
            <ListItemButton
                className={cx(classes.root, className)}
                component={Link}
                to={link ?? '#'}
                onClick={handleClick}
            >
                <ListItemText classes={{ primary: classes.text }}>
                    <span className={cx(classes.title, { [classes.accent]: accent })}>{title}</span>
                </ListItemText>
                {chevron}
            </ListItemButton>
            {isEmpty(children) ? null : (
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    {children}
                </Collapse>
            )}
        </>
    );
};

export default MenuItem;
