import { useCallback, useEffect, useState } from 'react';

import { logger } from 'services/logger';

interface Options {
    duration?: number;
}

const DEFAULT_DURATION = 1000;

async function copyToClipboard(value: string) {
    if (navigator.clipboard) {
        try {
            await navigator.clipboard.writeText(value);
            return true;
        } catch (error) {
            logger.error(error);
            return false;
        }
    } else {
        return false;
    }
}

function useCopyToClipboard(
    { duration }: Options = { duration: DEFAULT_DURATION }
): [(value: string) => Promise<void>, boolean] {
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        if (!isCopied) {
            return;
        }
        const timerId = window.setTimeout(() => setIsCopied(false), duration);
        return () => window.clearTimeout(timerId);
    }, [isCopied, duration]);

    const copy = useCallback(async (value: string) => {
        const isCopied = await copyToClipboard(value);

        setIsCopied(isCopied);
    }, []);

    return [copy, isCopied];
}

export default useCopyToClipboard;
