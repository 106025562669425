import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import { SEARCH_TYPE_COOKIE } from 'app/constants';
import useStores from 'hooks/use-stores';
import { SearchType } from 'main/components/SearchBar';
import { setCookie } from 'utils/cookies';

import MenuItem from './components/MenuItem';
import SubMenu from './components/SubMenu';
import SubMenuGroup from './components/SubMenuGroup';
import SubMenuItem from './components/SubMenuItem';
import useStyles from './styles';

interface Props {
    className?: string;
    'data-testid'?: string;
}

const MenuDesktop: React.FC<Props> = ({ className }) => {
    const { classes, cx } = useStyles();

    const history = useHistory();
    const { searchStore } = useStores();

    const redirectToSearchWith = useCallback(
        (type: SearchType) => (event: React.MouseEvent) => {
            event.preventDefault();
            const { query, version, software, state } = searchStore;
            searchStore.setParameters({ type, query, version, software, state });
            setCookie(SEARCH_TYPE_COOKIE, type, { ['max-age']: Number.MAX_SAFE_INTEGER });
            history.push('/search');
        },
        [history, searchStore]
    );

    return (
        <div className={cx(classes.root, className)}>
            <MenuItem title={'Applications'} data-testid="menuApplication">
                <SubMenu>
                    <SubMenuGroup title="Search">
                        <SubMenuItem
                            title="Basic Search"
                            data-testid="basicSearch"
                            description="Search using prebuilt filters"
                            link="/search"
                            onClick={redirectToSearchWith(SearchType.elastic)}
                        />
                        <SubMenuItem
                            title="Lucene Search"
                            data-testid="luceneSearch"
                            description="Use powerful query language"
                            link="/search"
                            onClick={redirectToSearchWith(SearchType.simple)}
                        />
                        <SubMenuItem
                            title="Product Search"
                            data-testid="productSearch"
                            description="Search by product and version"
                            link="/search"
                            onClick={redirectToSearchWith(SearchType.product)}
                        />
                    </SubMenuGroup>
                    <SubMenuGroup title="Dashboards">
                        <SubMenuItem
                            title="Scanner dashboard"
                            data-testid="scannerDashboard"
                            description="Linux and Windows scan results"
                            link="/scanner"
                        />
                        <SubMenuItem
                            title="Perimeter Control Dashboard"
                            data-testid="perimeterScannerDashboard"
                            description="Perimeter Scan Results"
                            link="/vscanner"
                        />
                    </SubMenuGroup>
                    <SubMenuGroup title="Notifications">
                        <SubMenuItem
                            title="Email Notifications"
                            data-testid="emailNotifications"
                            description="Email Notifications"
                            link="/email"
                        />
                        <SubMenuItem
                            title="Webhook Notifications"
                            data-testid="webhookNotifications"
                            description="Configure webhook notifications"
                            link="/webhook"
                        />
                    </SubMenuGroup>
                </SubMenu>
            </MenuItem>
            <MenuItem title={'Our solutions'} data-testid="ourSolutions" accent>
                <SubMenu>
                    <SubMenuGroup title="Offensive Security">
                        <SubMenuItem
                            title="Perimeter Control Tool"
                            data-testid="perimeterControlTool"
                            description="24/7 watch for open ports and vulnerable services behind them on your network"
                            link="/perimeter-control"
                        />
                        <SubMenuItem
                            title="Lucene Search"
                            data-testid="luceneSearch"
                            description="Use powerful query language"
                            link="/search"
                            onClick={redirectToSearchWith(SearchType.simple)}
                        />
                        <SubMenuItem
                            title="Vulnerabilities Intelligence"
                            data-testid="vulnerabilitiesIntelligence"
                            description="Normalized machine-readable feed of latest CVEs, exploits, 0days, news and advisories"
                            link="/feed"
                        />
                    </SubMenuGroup>
                    <SubMenuGroup title="Vulnerability Assessment">
                        <SubMenuItem
                            title="Linux Scanner"
                            data-testid="linuxScanner"
                            description="Detect all known vulnerabilities in Linux environment with minimum false positives"
                            link="/linux"
                        />
                        <SubMenuItem
                            title="Windows Scanner"
                            data-testid="windowsScanner"
                            description="Vulnerabilities assessment for Windows environment with minimum false positives"
                            link="/windows"
                        />
                    </SubMenuGroup>
                    <SubMenuGroup title="Product Development">
                        <SubMenuItem
                            title="Developer SDK"
                            data-testid="developerSdk"
                            description="Powerful API to power your product with vulnerabilities intelligence"
                            link="/sdk"
                        />
                        <SubMenuItem
                            title="Security Intelligence feeds"
                            data-testid="securityIntelligenceFeeds"
                            description="All-in-one Vulnerability Intelligence to elevate your security services"
                            link="/mssp"
                            reload
                        />
                    </SubMenuGroup>
                </SubMenu>
            </MenuItem>
            <MenuItem title={'Resources'} data-testid="resources">
                {
                    <SubMenu>
                        <SubMenuGroup title="Resources">
                            <SubMenuItem
                                title="Statistics & Sources"
                                data-testid="statisticsSources"
                                description="Vulnerability analysis of Vulners database"
                                link="/stats"
                            />
                            <SubMenuItem
                                title="Plugins"
                                description="Integrations to power your security tools with vulnerabilities intelligence"
                                link="/plugins"
                            />
                        </SubMenuGroup>
                        <SubMenuGroup title="Documentation">
                            <SubMenuItem
                                title="API docs"
                                description="API documentation for developers and integrations"
                                link="/docs/"
                                reload
                            />
                            <SubMenuItem
                                title="F.A.Q"
                                description="Most frequently asked questions about Vulners"
                                link="/faq"
                            />
                        </SubMenuGroup>
                        <SubMenuGroup title="Other">
                            <SubMenuItem
                                title="Blog"
                                description="Articles about vulnerabilities, exploits, news and advisories"
                                link="/blog/"
                                reload
                            />
                            <SubMenuItem
                                title="Glossary"
                                description="Glossary of terms used in Vulners"
                                link="/glossary/"
                                reload
                            />
                        </SubMenuGroup>
                    </SubMenu>
                }
            </MenuItem>
            <MenuItem title={'Contacts'} data-testid="contacts" link="/contacts" />
            <MenuItem title={'Pricing'} data-testid="pricing" link="/pricing" />
        </div>
    );
};

export default observer(MenuDesktop);
