import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { Chip, Zoom } from '@mui/material';

import useStores from 'hooks/use-stores';
import { SearchType } from 'main/components/SearchBar';

import useStyles from './styles';

interface Props {
    className?: string;
}

const DELAY_BASE = 0.25;
const DELAY_STEP = 0.1;

const Templates: React.FC<Props> = ({ className }) => {
    const { classes, cx } = useStyles();
    const history = useHistory();

    const { sTemplatesStore, searchStore } = useStores();
    useEffect(() => {
        sTemplatesStore.loadData();
    }, [sTemplatesStore]);

    const handleClick = useCallback(
        ({ query, aliasUrl }) => (event: React.MouseEvent) => {
            event.preventDefault();
            history.push(aliasUrl || `/search?query=${query}`);
            searchStore.setParameters({
                type: SearchType.simple,
                query: query,
                software: '',
                version: '',
                state: {}
            });
            return searchStore.performFetch();
        },
        [history, searchStore]
    );

    return (
        <div className={cx(classes.root, className)}>
            {sTemplatesStore.templates.map(({ alias, aliasUrl, query }, index) => (
                <Zoom key={alias} in style={{ transitionDelay: `${DELAY_BASE + ((index * DELAY_STEP) % 1)}s` }}>
                    <Chip
                        component={Link}
                        target="_blank"
                        to={aliasUrl || `/search?query=${query}`}
                        variant="outlined"
                        className={classes.chip}
                        label={alias}
                        onClick={handleClick({ query, aliasUrl })}
                    />
                </Zoom>
            ))}
        </div>
    );
};

export default observer(Templates);
