import React from 'react';

import useStyles from './styles';

interface Props {
    image: string;
    title: string;
    date: string;
    link: string;
    className?: string;
}

const BlogPostItem: React.FC<Props> = ({ link, image, title, date, className }) => {
    const { classes, cx } = useStyles();

    return (
        <a href={link} target="_blank" className={cx(classes.root, className)} rel="noreferrer noopener">
            <img src={image} alt={title} className={classes.image} />
            <p className={classes.title}>{title}</p>
            <span className={classes.date}>{date}</span>
        </a>
    );
};

export default BlogPostItem;
