import React, { useLayoutEffect, useRef } from 'react';
import { useStyles } from 'tss-react';

import hljs from 'modules/hljs';

import CodeBlock from './CodeBlock';

interface Props {
    className?: string;
    language?: string;
    element?: React.ElementType;
    children: React.ReactNode;
}

const Hljs: React.FC<Props> = ({ className, element: Element = CodeBlock, language = '', children }) => {
    const { cx } = useStyles();

    const elementRef = useRef<HTMLElement | null>();

    useLayoutEffect(() => {
        if (elementRef.current) {
            elementRef.current.querySelectorAll('pre code').forEach((node) => {
                if (node instanceof HTMLElement) {
                    hljs.highlightBlock(node);
                }
            });
        }
    }, [language, children]);

    return (
        <Element ref={elementRef} className={cx(className, { [language]: Boolean(hljs.getLanguage(language)) })}>
            {children}
        </Element>
    );
};

export default React.memo(Hljs);
