import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';

import { SearchOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import useIsMobile from 'hooks/use-is-mobile';
import useShortcutSearchInput from 'hooks/use-shortcut-search-input';
import useStores from 'hooks/use-stores';
import { SearchType } from 'main/components/SearchBar';
import AutoCompleteInput from 'main/components/SearchBar/components/AutoCompleteInput';
import useSuggestions from 'main/components/SearchBar/hooks/use-suggestions';

import useStyles from './styles';

interface Props {
    className?: string;
}

const SearchBar: React.FC<Props> = ({ className }) => {
    const { classes, cx } = useStyles();
    const history = useHistory();
    const { searchStore } = useStores();

    const autoCompleteInputRef = useShortcutSearchInput();
    const isMobile = useIsMobile();

    const [value, setValue] = useState({
        type: SearchType.simple,
        query: '',
        software: '',
        version: '',
        state: { searchTerm: '' }
    });

    const { suggestions, isLoading } = useSuggestions(value);

    const handleChange = useCallback(
        (query: string) =>
            setValue((value) => ({
                ...value,
                query,
                state: { searchTerm: query.trim(), sortDirection: 'desc', sortField: 'published' }
            })),
        []
    );

    const handleSubmit = useCallback(() => {
        const { type, query, software, version, state } = value;
        if (query) {
            searchStore.setParameters({ type, query, software, version, state });
            history.push(`/search?query=${query}`);
            searchStore.performFetch();
        }
    }, [history, searchStore, value]);

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                event.stopPropagation();
                handleSubmit();
            }
        },
        [handleSubmit]
    );

    return (
        <div className={cx(classes.root, className)}>
            <AutoCompleteInput
                id="__search_bar_autocomplete_input__"
                ref={autoCompleteInputRef}
                loading={isLoading}
                placeholder={isMobile ? 'Search' : 'Searching through 3M+ vulnerabilities and exploits'}
                className={className}
                classes={{ root: classes.autocomplete, list: classes.list, input: classes.input }}
                value={value.query}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                options={suggestions}
            />
            <IconButton className={classes.icon} onClick={handleSubmit}>
                <SearchOutlined />
            </IconButton>
        </div>
    );
};
export default observer(SearchBar);
